import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import Task, { TaskPage } from '@shared/models/task';
import { User } from '@shared/models/user';
import { ApiService } from '@shared/services';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class TasksService {
    Users: BehaviorSubject<User[]> = new BehaviorSubject<User[]>([]);

    private isLoggingEnabled = !environment.production;

    constructor(private apiService: ApiService) {}

    getTaskUploadingURL(taskId: any): string {
        return `${this.apiService.apiUrl}/core/tasks/${taskId}/documents/`;
    }

    /**
     * Returns an array of tasks assigned by the current user, using pagination
     */
    getTasksPage(page: number = 1): Observable<TaskPage> {
        return this.apiService.getTasksPage(page);
    }

    getTask(taskId: number): Observable<Task> {
        return this.apiService.getTask(taskId);
    }

    /**
     * Creates a task for the current user
     * @param task The task object
     */
    createTask(task: Task): Observable<Task> {
        return this.apiService.createTask(task);
    }

    /**
     * Updates a task with the given properties
     * @param taskId The id of the task to be updated
     * @param data The properties to be updated
     */
    updateTask(taskId: number, data: any): Observable<Task> {
        return this.apiService.updateTask(taskId, data);
    }

    /**
     * Deletes a task
     * @param taskId The id of the task to be deleted
     */
    deleteTask(taskId: number): Observable<any> {
        return this.apiService.deleteTask(taskId);
    }

    /**
     * Returns an array of users
     */
    getUsers(): Observable<User[]> {
        return this.apiService.getUsers().pipe(
            tap((users: User[]) => {
                this.Users.next(users);
            }),
        );
    }

    getTaskDocuments(taskId: any): Observable<any> {
        return this.apiService.getTaskDocuments(taskId);
    }
}
